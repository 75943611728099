import { Theme, useTheme } from '@emotion/react';
import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';

const buttonMainStyle = (theme: Theme) => ({
  ...theme.mixins.fonts.fontButton,
  border: 'none',
  cursor: 'pointer',
  textDecoration: 'none',
  color: '#fff',
  height: '2.56em',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '1.28em',
  padding: '0 1.6em',
  backgroundColor: theme.colors.primary,
  backgroundImage: `linear-gradient(120deg, ${theme.colors.primary} 0%, ${theme.colors.gradient} 100%)`,
  backgroundSize: '500% 500%',
  margin: '0 auto',
  animation: 'move-background 60s linear infinite',
  transition: 'all 0.2s ease, box-shadow 0.4s ease',
});

const sizeStyle = (theme: Theme) => ({
  sm: {
    ...theme.mixins.fonts.fontMedium,
    padding: '0 1.2em',
    height: '2em',
    borderRadius: '1em',

    '&>svg': {
      width: '16px',
      height: '16px',
    },
  },
});

const modifications = {
  shadow: {
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.25)',
  },
};

export function GBButton({
  size,
  modification,
  text,
  textStyle,
  buttonStyle,
  callback,
  ...restProps
}: {
  size: string;
  callback?: () => void;
  modification: string;
  text: string;
  textStyle: object;
  buttonStyle: object;
}) {
  const theme = useTheme();
  return (
    <div
      onClick={callback || undefined}
      css={{
        ...buttonMainStyle(theme),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...sizeStyle(theme)[size],
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...modifications[modification],
        ...buttonStyle,
      }}
      {...restProps}
    >
      <span css={{ ...textStyle }}>{text}</span>
    </div>
  );
}

export function ButtonApp({
  href,
  children,
  target,
}: {
  href: string;
  target: string;
  children: ReactNode;
}) {
  const theme = useTheme();
  return (
    <a
      href={href}
      target={target}
      // button-app
      css={{
        width: 'calc(100% / 3)',
        height: '50px',
        display: 'inline-flex',
        borderRadius: theme.constants.borderRadius,
        backgroundColor: theme.colors.white,
        color: theme.colors.red,
        textDecoration: 'none',
        paddingLeft: '16px',
        marginRight: '10px',
        alignItems: 'center',
        [DEVICE_SCREENS_MAX_STRING.xl]: {
          width: '100%',
          marginBottom: 5,
          justifyContent: 'center',
          paddingLeft: 0,
          fontSize: 14,
        },
        [DEVICE_SCREENS_MAX_STRING.lg]: {
          paddingLeft: '12px',
        },
      }}
    >
      {children}
    </a>
  );
}

export function StandardButton({
  children,
  callback,
}: {
  children: ReactNode;
  callback?: () => void;
}) {
  const theme = useTheme();
  return (
    <button
      onClick={callback}
      type='button'
      // button-app
      css={{
        minWidth: 90,
        height: 45,
        backgroundColor: 'transparent',
        borderRadius: 4,
        padding: '0 0.8em',
        fontSize: '1rem',
        fontFamily: 'Golos',
        fontWeight: 500,
        border: `2px solid ${theme.colors.red}`,
        color: '#000',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'all 0.2s ease',
        ':hover': {
          background: theme.colors.red,
          color: theme.colors.white,
        },
      }}
    >
      {children}
    </button>
  );
}

export function RedButton({
  children,
  callback,
  montserrat = false,
  full = false,
  disabled = false,
  subscription_confirmed,
  ...restProps
}: {
  children: ReactNode;
  montserrat?: boolean;
  full?: boolean;
  disabled?: boolean;
  callback?: () => void;
  subscription_confirmed?: boolean;
}) {
  const theme = useTheme();
  return (
    <button
      onClick={disabled ? undefined : callback}
      type='button'
      // button-app
      css={{
        fontFamily: 'Golos',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0em',
        border: 'none',
        textDecoration: 'none',
        color: theme.colors.white,
        height: '2.56em',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '1.28em',
        padding: '0 1.6em',
        backgroundColor: theme.colors.primary,
        backgroundImage: `linear-gradient(120deg, ${theme.colors.primary} 0%, ${theme.colors.gradient} 100%)`,
        backgroundSize: '500% 500%',
        margin: '0 auto',
        transition: 'all 0.2s ease, box-shadow 0.4s ease',
        animation: 'move-background 60s linear infinite',
        ':hover': {
          boxShadow: `0px 0px 10px 0px ${theme.colors.gradient}`,
        },
        ...(montserrat
          ? {
              fontFamily: 'Montserrat',
              fontSize: 15,
              lineHeight: '15px',
              fontWeight: 600,
            }
          : {}),
        ...(full ? { width: '100%', height: 35 } : {}),
        ...(disabled
          ? {
              background: '#BFC1C3',
              ':hover': {
                boxShadow: 'unset',
              },
            }
          : {}),
        ...(subscription_confirmed
          ? {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              gap: 10,
              cursor: 'default',
              background: '#FFFFFF',
              color: '#249608',
            }
          : {}),
      }}
      {...restProps}
    >
      {children}
    </button>
  );
}

export function BuyButton({
  children,
  callback,
  full = false,
  disabled = false,
  subscription_confirmed,
  ...restProps
}: {
  children: ReactNode;
  full?: boolean;
  disabled?: boolean;
  callback?: () => void;
  subscription_confirmed?: boolean;
}) {
  const theme = useTheme();
  return (
    <button
      onClick={disabled ? undefined : callback}
      type='button'
      // button-app
      css={{
        width: 200,
        fontFamily: 'Golos',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0em',
        background: '#FA4530',
        borderRadius: 4,
        display: 'inline-block',
        padding: 10,
        textAlign: 'center',
        color: theme.colors.white,
        transition: 'all 0.2s ease, box-shadow 0.4s ease',
        ':hover': {
          boxShadow: `0px 0px 10px 0px ${theme.colors.gradient}`,
        },
        ...(full ? { width: '100%', height: '3.511em', borderRadius: 0 } : {}),
        ...(disabled
          ? {
              background: '#A5A2A1 !important',
              ':hover': {
                boxShadow: 'unset',
              },
            }
          : {}),
        ...(subscription_confirmed
          ? {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 8,
              cursor: 'default',
              background: '#FFFFFF',
              color: '#249608',
            }
          : {}),
      }}
      {...restProps}
    >
      {children}
    </button>
  );
}

export const SubmitWideButton = styled.button(() => ({
  width: '100%',
  height: 45,
  border: '2px solid #F44336',
  borderRadius: 4,
  transition: '.2s ease',
  ':hover': {
    background: '#F44336',
    color: '#fff',
  },
}));
